import React from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { stringToSlug } from "../utils/stringManipulation";

import chatSvg from "../images/chat_icon.svg";
import chatWhiteSvg from "../images/chat_icon_white.svg";
import homeSvg from "../images/home_icon.svg";
import homeWhiteSvg from "../images/home_icon_white.svg";
import worldSvg from "../images/world_icon.svg";
import worldWhiteSvg from "../images/world_icon_white.svg";

const HowItWorksRow = (props) => {
  const { category } = props;

  const slugForCategoryHowToPage = (category) => {
    if (category == "Chirurgia Estetica") {
      return "chirurgia-estetica";
    } else if (category == "Trapianto Capelli") {
      return "trapianto-capelli";
    } else if (category == "Fertilità") {
      return "fertilita";
    }
  }

  const firstStepText = (category) => {
    if (category == "Chirurgia Estetica") {
      return "Siamo a vostra disposizione via Whatsapp, Messenger, sito per offrirvi una consulenza gratuita, parlare delle vostre problematiche e consigliarvi una soluzione personalizzata";
    } else if (category == "Trapianto Capelli") {
      return "Siamo a vostra disposizione via Whatsapp, Messenger, sito per offrirvi una consulenza gratuita, parlare delle vostre problematiche e consigliarvi una soluzione personalizzata";
    } else if (category == "Fertilità") {
      return "Siamo a vostra disposizione via Whatsapp, Messenger, sito per offrirvi una consulenza gratuita, parlare delle vostre problematiche e consigliarvi una soluzione personalizzata";
    } else {
      return "Decideremo insieme, in base alle vostre esigenze, quali trattamenti sono più adatti a voi";
    }
  }

  const secondStepText = (category) => {
    if (category == "Chirurgia Estetica") {
      return "Prepareremo un programma di viaggio personalizzato, sarete affiancati da una persona che parla italiano per tutta la vostra permanenza in Lituania e prima dell'intervento, sarete visitati dal chirurgo prescelto";
    } else if (category == "Trapianto Capelli") {
      return "Prepareremo un programma di viaggio personalizzato, e durante tutta la vostra permanenza in Clinica sarete affiancati da una persona che parla italiano. Prima dell'intervento, sarete visitati dal medico specializzato";
    } else if (category == "Fertilità") {
      return "Prepareremo un programma di viaggio personalizzato, e durante tutta la vostra permanenza in Clinica sarete affiancati da una persona che parla italiano. Prima dell'intervento, sarete visitati dal medico specializzato";
    } else {
      return "Un assistente personale che parla italiano vi accoglierà in aeroporto, vi affiancherà costantemente per tutto il tempo che sarete in Clinica e nei momenti liberi, se necessario, per garantire un'esperienza all'estero senza preoccupazioni";
    }
  }

  const thirdStepText = (category) => {
    if (category == "Chirurgia Estetica") {
      return "Il giorno della ripartenza vi accompagneremo all'aeroporto e una volta a casa, vi ricorderemo di eventuali controlli post-operatori programmati e restiamo in contatto telefonico";
    } else if (category == "Trapianto Capelli") {
      return "Il giorno della ripartenza verrete riaccompagnati all'aeroporto e una volta a casa, restiamo a disposizione e in contatto telefonico";
    } else if (category == "Fertilità") {
      return "Il giorno della ripartenza verrete riaccompagnati all'aeroporto e una volta a casa, restiamo a disposizione e in contatto telefonico";
    } else {
      return "Restiamo in contatto telefonico e rimaniamo a vostra disposizione per qualsiasi aiuto";
    }
  }

  return (
    <div
      className={`sterea-center-column-wrapper py-12 px-min ${
        category ? "sterea-blue-gradient text-white" : "bg-sterea-yellow"
      }`}
    >
      <div className="sterea-center-column flex flex-col justify-center">
        <h2 className="text-center">Come funziona</h2>
        <div className="flex flex-wrap pt-12">
          <div className="w-full lg:w-1/3 mb-12 lg:mb-0 lg:px-3 flex flex-col items-center text-center">
            <img
              src={category ? chatWhiteSvg : chatSvg}
              className="mb-6 h-16"
              alt="Icona di una chat, che rappresenta la fase di consultazione con Sterea"
            />
            <p>
              <span className="font-bold">1.</span> {firstStepText(category && category.Name)}
            </p>
          </div>
          <div className="w-full lg:w-1/3 mb-12 lg:mb-0 lg:px-3 flex flex-col items-center text-center">
            <img
              src={category ? worldWhiteSvg : worldSvg}
              className="mb-6 h-16"
              alt="Icona di un mondo, che rappresenta il viaggio all'estero con Sterea"
            />
            <p>
              <span className="font-bold">2.</span>  {secondStepText(category && category.Name)}
            </p>
          </div>
          <div className="w-full lg:w-1/3 lg:px-3 flex flex-col items-center text-center">
            <img
              src={category ? homeWhiteSvg : homeSvg}
              className="mb-6 h-16"
              alt="Icon della casa, che rappresenta il ritorno a casa dopo l'intervento con Sterea"
            />
            <p>
              <span className="font-bold">3.</span> {thirdStepText(category && category.Name)}
            </p>
          </div>
        </div>
        {category && ["Chirurgia Estetica", "Fertilità", "Trapianto Capelli"].includes(category.Name) && <div className="flex justify-center mt-12">
          <Link to={`/come-funziona/${slugForCategoryHowToPage(category.Name)}`} className={`${category ? "sterea-yellow-button" : "sterea-blue-purple-button"}`}>Scopri di più</Link>
        </div>}
      </div>
    </div>
  );
};

export default HowItWorksRow;
